import { HttpClient } from '@/services/http';

export class IntegrationService extends HttpClient {
  async getIntegration(params) {
    const result = await this.get('v1/integration', params);
    return result;
  }

  async getMovementDetail(id) {
    const result = await this.get(`v1/integration/${id}/detail`);
    return result;
  }

  async reprocessIntegration(id) {
    const result = await this.post(`v1/integration/${id}/reprocess`);
    return result;
  }
}
