<template>
  <v-container>
    <BaseTitlePage />

    <BaseSearchPage title="Consulte os beneficiários">
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6">
            <v-switch
              label="Pesquisar por operadora"
              class="mt-0 pt-0"
              v-model="filter.searchByInsuranceCompany"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            xl="5"
            lg="5"
            md="5"
            sm="6"
            v-if="filter.searchByInsuranceCompany"
          >
            <BaseInsuranceCompany
              label="Seguradora / Operadora"
              hide-details
              clearable
              v-model="filter.insuranceCompanyId"
            />
          </v-col>
          <template v-else>
            <v-col cols="12" xl="5" lg="5" md="5" sm="6">
              <BaseCustomersAutocomplete
                label="Cliente"
                id="customerId"
                name="customerId"
                hide-details
                clearable
                v-model="filter.customerId"
              />
            </v-col>
            <v-col
              cols="12"
              xl="5"
              lg="5"
              md="5"
              sm="6"
              v-if="filter.customerId"
            >
              <BaseContractsMovements
                label="Contrato"
                id="contractId"
                name="contractId"
                hide-details
                clearable
                :multiple="false"
                :customerId="filter.customerId"
                v-model="filter.contractId"
              />
            </v-col>
          </template>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-autocomplete
              label="Status"
              id="situation"
              name="situation"
              dense
              outlined
              hide-details
              clearable
              :items="situation"
              v-model="filter.situation"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="6">
            <BaseButton
              id="btn-search"
              name="btn-search"
              title="Filtrar"
              icon="fa-solid fa-magnifying-glass"
              height="40"
              color="primary"
              @click="search"
            />
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de beneficiários">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.insuranceCompanyName`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar size="50" tile v-on="on">
                  <v-img
                    :src="item.thumbnail"
                    :alt="item.insuranceCompanyName"
                    contain
                  />
                </v-avatar>
              </template>
              <span>{{ item.insuranceCompanyName }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.customerName`]="{ item }">
            <span :title="item.customerName">
              {{ doTruncateText(item.customerName, 20) }}
            </span>
          </template>

          <template v-slot:[`item.contractName`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ item.contractName }}
                </span>
              </template>
              <span>{{ item.productType }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.beneficiaryName`]="{ item }">
            <span :title="item.beneficiaryName">
              {{ doTruncateText(item.beneficiaryName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.holderName`]="{ item }">
            <span :title="item.holderName">
              {{ doTruncateText(item.holderName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  :color="getSituationIconColor(item.situation)"
                  v-on="on"
                >
                  {{ getSituationIcon(item.situation) }}
                </v-icon>
              </template>
              <span>{{ item.situation }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab x-small text v-on="on">
                  <v-icon color="primary" small @click="edit(item)">
                    fa-regular fa-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Visualizar cadastro</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab x-small text v-on="on">
                  <v-icon color="success" small @click="viewError(item)">
                    fa-regular fa-file-code
                  </v-icon>
                </v-btn>
              </template>
              <span>Visualizar integração</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <DialogDependent
      v-if="dialog"
      :employeeId="selectItem.employeeId"
      :customerId="selectItem.customerId"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />

    <EditEmployee
      v-if="dialogEmployee"
      :dialog="dialogEmployee"
      :employeeId="selectItem.employeeId"
      :customerId="selectItem.customerId"
      :contracts="selectItem.contracts"
      @closeModal="closeModal"
    />

    <ErrorEmployee
      v-if="dialogError"
      :dialog="dialogError"
      :integrationId="selectItem.id"
      :situation="selectItem.situation"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { getColor, doTruncateText } from '@/helpers/utils';
import { IntegrationService } from '@/services/api/integration';

export default {
  components: {
    EditEmployee: () => import('./Edit'),
    ErrorEmployee: () => import('./Error'),
    DialogDependent: () =>
      import('@/components/pages/movements/dialogs/dialog-dependents')
  },

  data: () => ({
    filter: {
      searchByInsuranceCompany: true,
      insuranceCompanyId: null,
      contractId: null,
      customerId: null,
      situation: null
    },
    options: {},
    headers: [
      { text: 'Operadora', value: 'insuranceCompanyName' },
      { text: 'Cliente', value: 'customerName' },
      { text: 'Contrato', value: 'contractName' },
      { text: 'Beneficiário', value: 'beneficiaryName' },
      { text: 'Titular', value: 'holderName' },
      { text: 'Movimento', value: 'movementType' },
      { text: 'Status', value: 'situation' },
      { text: 'Tipo', value: 'type' },
      { text: '', value: 'action', width: '10%' }
    ],
    dialogError: false,
    dialogEmployee: false,
    dialog: false,
    dialogEdit: false,
    dialogTitle: '',
    selectItem: {},
    situation: ['Em andamento', 'Erro', 'Integrado'],
    items: [],
    totalRecords: 0
  }),

  methods: {
    doTruncateText,
    getColor,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const integrationService = new IntegrationService();
        const { status, data } = await integrationService.getIntegration({
          page,
          take: itemsPerPage,
          ...this.filter
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    edit(integration) {
      if (integration.holderName) {
        this.openDialogDependent(integration);
      } else {
        this.selectItem = Object.assign({}, integration);
        this.dialogEmployee = true;
      }
    },

    viewError(integration) {
      this.selectItem = Object.assign({}, integration);
      this.dialogError = true;
    },

    openDialogDependent(integration) {
      this.selectItem = {
        id: integration.beneficiaryId,
        employeeId: integration.employeeId,
        customerId: integration.customerId
      };

      this.dialog = true;
      this.dialogEdit = true;
      this.dialogTitle = 'Visualizar dependente';
    },

    closeModal(search) {
      this.dialogError = false;
      this.dialogEmployee = false;
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogTitle = '';
      this.selectItem = {};

      if (search) {
        this.search();
      }
    },

    getSituationIcon(situation) {
      let icons = {
        'Em andamento': 'fa-regular fa-clock',
        Erro: 'fa-solid fa-xmark',
        Integrado: 'fa-solid fa-check'
      };

      return icons[situation] || icons['Integrado'];
    },

    getSituationIconColor(situation) {
      let icons = {
        'Em andamento': 'warning',
        Erro: 'error',
        Integrado: 'success'
      };

      return icons[situation] || icons['Integrado'];
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
